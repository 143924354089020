import React from 'react';
import { useIntl } from 'react-intl';
import { StyledButton } from './ButtonLeaf.styled';
import { ButtonProps } from 'components/Button/Button';

export interface ButtonLeafProps extends ButtonProps {
  ariaLabel?: string;
}

const ButtonLeaf = ({ ariaLabel, children, className, ...props }: ButtonLeafProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledButton
      aria-label={ariaLabel || formatMessage({ id: 'button_label' })}
      className={className}
      size="icon"
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default ButtonLeaf;
