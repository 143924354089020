import { Icon as IconName } from 'constants/icon';
import { Color } from 'types/Color';
import { IconStyle, Spacer } from '../../../constants/styling';
import usePlatform from '../../../hooks/usePlatform';
import Typography from '../../Typography/Typography';
import Icon from '../Icon';
import { StyledLeaf, StyledSelectableIcon, StyledSelectableIconProps } from './SelectableIcon.styled';

export interface SelectableIconProps extends StyledSelectableIconProps {
  ariaLabel?: string;
  children?: React.ReactNode;
  className?: string;
  iconColor?: Color;
  iconName?: IconName;
  iconSize?: Spacer;
  iconStyling?: IconStyle;
  onClick?: () => void;
  showLeaf?: boolean;
}

const SelectableIcon = ({
  active,
  activeColor,
  ariaLabel,
  backgroundColor,
  borderRadius,
  children,
  className,
  iconColor,
  iconName,
  iconSize = 125,
  iconStyling,
  label,
  onClick,
  showCircle,
  showLeaf,
  size,
}: SelectableIconProps) => {
  const { isKrefel } = usePlatform();

  if (!iconName) return null;

  if (isKrefel && !label && showLeaf) {
    return (
      <StyledLeaf active={active} label={ariaLabel} onClick={onClick}>
        <Icon type="custom" color={iconColor} size={iconSize} name={iconName} />
        {children}
      </StyledLeaf>
    );
  }

  return (
    <StyledSelectableIcon
      active={active}
      activeColor={activeColor}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      aria-label={ariaLabel}
      className={className}
      size={size}
      onClick={(event) => {
        event.preventDefault();
        onClick?.();
      }}
      role="button"
      showCircle={showCircle}
      tabIndex={0}
      label={label}
    >
      <Icon
        type="custom"
        // Set activeColor when no circle and no leaf is showing
        color={!showCircle && !showLeaf && active ? activeColor : iconColor}
        iconStyling={iconStyling}
        name={iconName}
        size={iconSize}
      />

      {label && <Typography fontWeight={active ? 'bold' : undefined}>{label}</Typography>}
      {children}
    </StyledSelectableIcon>
  );
};

export default SelectableIcon;
