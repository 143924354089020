import { cn } from 'utils/cn';
import { Text, TextProps } from '../Text/Text';

type PriceProps = TextProps & {
  children?: number | string;
  prefix?: string;
};

const StrikePrice = ({ children, className, prefix, tag = 'span', type = 'body' }: PriceProps) => (
  <Text type={type} tag={tag} className={cn('leading-5 text-accent-60 line-through', className)}>
    {prefix ? `${prefix} ` : ''}
    {children}
  </Text>
);

const Price = ({ children, className, prefix, tag = 'span', type = 'h3' }: PriceProps) => (
  <Text type={type} tag={tag} className={cn('whitespace-nowrap', className)}>
    {prefix ? `${prefix} ` : ''}
    {children}
  </Text>
);

export { Price, StrikePrice, type PriceProps };
