import { forwardRef } from 'react';
import { cn } from 'utils/cn';
import { Text, TextProps } from '../Text/Text';

type Props = TextProps & {
  children: string;
};

const Badge = forwardRef<HTMLDivElement, Props>(({ children, className, tag = 'div', type = 'small' }: Props, ref) => (
  <Text ref={ref} type={type} tag={tag} className={cn('w-fit rounded-pill bg-primary-30/10 px-3 py-1', className)}>
    {children}
  </Text>
));

export { Badge };
