import { keepPreviousData, skipToken, useQuery } from '@tanstack/react-query';
import config from 'config';
import useRouter from 'hooks/useRouter';
import { useRouterParam } from 'hooks/useRouterParam';
import { storesKeys } from '.';
import {
  fetchCustomerServiceDetails,
  fetchPickupLocationsByProductCode,
  fetchStore,
  fetchStores,
  fetchStoresByLocation,
  fetchStoresByProductCode,
} from './connector';

type UseStoresParams = {
  latitude?: number;
  longitude?: number;
  open?: boolean;
  openSunday?: boolean;
  signings?: string;
};

const useStores = ({ latitude, longitude, open, openSunday, signings }: UseStoresParams) => {
  const { locale } = useRouter();
  return useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => fetchStores({ latitude, locale, longitude, open, openSunday, signings }),
    queryKey: storesKeys.stores({ latitude, locale, longitude, open, openSunday, signings }),
  });
};

const useStoreDetail = (id?: string) => {
  const { locale } = useRouter();
  const storeIdParam = useRouterParam('id');
  const storeId = id ?? storeIdParam ?? '';
  return useQuery({
    enabled: !!storeId,
    placeholderData: keepPreviousData,
    queryFn: () => fetchStore({ locale, storeId }),
    queryKey: storesKeys.store({ locale, storeId }),
  });
};

const useCustomerServiceDetails = () => {
  const { locale } = useRouter();
  return useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => fetchCustomerServiceDetails({ locale }),
    queryKey: storesKeys.customerServiceDetails({ locale }),
  });
};

type UseStoresByLocationParams = {
  countryCode?: string;
  enabled?: boolean;
  fields?: string;
  location: string;
  pageSize?: number;
  postalCode: string;
};

const useStoresByLocation = ({
  countryCode = config.defaultCountry,
  enabled = true,
  fields = 'FULL',
  location,
  pageSize = 5,
  postalCode,
}: UseStoresByLocationParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: enabled && !!countryCode && !!location && !!postalCode,
    placeholderData: keepPreviousData,
    queryFn:
      !!countryCode && !!location && !!postalCode
        ? () => fetchStoresByLocation({ countryCode, fields, locale, location, pageSize, postalCode })
        : skipToken,
    queryKey: storesKeys.storesByLocation({ countryCode, fields, locale, location, pageSize, postalCode }),
    select: (data) => data?.stores || [],
  });
};

type useStoresByProductCodeParams = {
  countryCode: string;
  enabled?: boolean;
  location: string;
  postalCode: string;
  productCode: string;
};

const useStoresByProductCode = ({
  countryCode = config.defaultCountry,
  enabled = true,
  location,
  postalCode,
  productCode,
}: useStoresByProductCodeParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchStoresByProductCode({ countryCode, locale, location, postalCode, productCode }),
    queryKey: storesKeys.storesByProductCode({ countryCode, locale, location, postalCode, productCode }),
    select: (data) => data?.stores || [],
  });
};

type UsePickupLocationsByProductCodeParams = {
  countryCode?: string;
  enabled?: boolean;
  location: string;
  postalCode: string;
  productCode: string;
};

const usePickupLocationsByProductCode = ({
  countryCode = config.defaultCountry,
  enabled = true,
  location,
  postalCode,
  productCode,
}: UsePickupLocationsByProductCodeParams) => {
  const { locale } = useRouter();
  return useQuery({
    enabled: enabled && !!productCode && !!location && !!postalCode,
    placeholderData: keepPreviousData,
    queryFn:
      !!productCode && !!location && !!postalCode
        ? () => fetchPickupLocationsByProductCode({ countryCode, locale, location, postalCode, productCode })
        : skipToken,
    queryKey: storesKeys.pickupLocationsByProductCode({ countryCode, locale, location, postalCode, productCode }),
    select: (data) => data?.pointOfServices || [],
  });
};

export {
  useCustomerServiceDetails,
  usePickupLocationsByProductCode,
  useStoreDetail,
  useStores,
  useStoresByLocation,
  useStoresByProductCode,
};
