import { useEffect, useState } from 'react';
import { BRAND_VALUES } from '../constants/brand';

// TODO: Remove this hook and use constants/brand.ts directly
/**
 * @deprecated This hook is deprecated. Use constants/brand.ts directly.
 */
const usePlatform = () => {
  const currentPlatform = process.env.NEXT_PUBLIC_PLATFORM;

  const [platform, setPlatform] = useState({
    currentPlatform,
    isHifi: false,
    isKrefel: false,
    isTones: false,
  });

  useEffect(() => {
    switch (currentPlatform) {
      case BRAND_VALUES.KREFEL.toLowerCase():
        setPlatform({ ...platform, isKrefel: true });
        break;
      case BRAND_VALUES.HIFI.toLowerCase():
        setPlatform({ ...platform, isHifi: true });
        break;
      case BRAND_VALUES.TONES.toLowerCase():
        setPlatform({ ...platform, isTones: true });
        break;
      default:
        break;
    }
  }, []);

  return platform;
};

export default usePlatform;
