import { useState } from 'react';
import { useInterval, useIsClient } from 'usehooks-ts';
import { cn } from 'utils/cn';
import { Text } from '../Text/Text';

const CountdownItem = ({ label, value }: { label?: string; value: number }) => {
  if (!label) return null;

  return (
    <div className="flex flex-col items-center">
      <Text className="font-bold leading-3">{value}</Text>
      {label && <Text type="small">{label}</Text>}
    </div>
  );
};

const Countdown = ({
  className,
  date,
  dayLabel,
  hourLabel,
  minuteLabel,
  secondLabel,
}: {
  className?: string;
  date: Date | string;
  dayLabel?: string;
  hourLabel?: string;
  minuteLabel?: string;
  secondLabel?: string;
}) => {
  const isClient = useIsClient();
  const now = new Date();
  const targetDate = new Date(date);
  const differenceInSeconds = (targetDate.getTime() - now?.getTime()) / 1000;
  const [timeRemaining, setTimeRemaining] = useState(differenceInSeconds);

  useInterval(() => setTimeRemaining((prev) => (prev === 0 ? prev : prev - 1)), timeRemaining > 0 ? 1000 : null);

  if (!isClient) return null;

  const hoursRemaining = dayLabel ? timeRemaining % 86400 : timeRemaining;
  const minutesRemaining = hourLabel ? timeRemaining % 3600 : timeRemaining;
  const secondsRemaining = minuteLabel ? timeRemaining % 60 : timeRemaining;

  const days = Math.round(Math.floor(timeRemaining / 86400));
  const hours = Math.round(Math.floor(hoursRemaining / 3600));
  const minutes = Math.round(Math.floor(minutesRemaining / 60));
  const seconds = Math.round(secondsRemaining);

  const countdownItems = [
    {
      label: dayLabel,
      value: days,
    },
    {
      label: hourLabel,
      value: hours,
    },
    {
      label: minuteLabel,
      value: minutes,
    },
    {
      label: secondLabel,
      value: seconds,
    },
  ].filter((item) => item.label);

  return (
    <Text tag="span" className={cn('flex w-fit items-center gap-1 rounded bg-countdown p-2', className)}>
      {countdownItems.map((item, index) => (
        <>
          <CountdownItem label={item.label} value={item.value} />
          {index !== countdownItems.length - 1 && ':'}
        </>
      ))}
    </Text>
  );
};

export { Countdown, CountdownItem };
