import type {
  FetchCustomerServiceDetailsParams,
  FetchPickupLocationsByProductCodeParams,
  FetchStoreParams,
  FetchStoresByLocationParams,
  FetchStoresByProductCodeParams,
  FetchStoresParams,
} from './connector';

export const storesKeys = {
  customerServiceDetails: (params: FetchCustomerServiceDetailsParams) => ['customerServiceDetails', params],
  pickupLocationsByProductCode: (params: FetchPickupLocationsByProductCodeParams) => [
    'pickupLocationsByProductCode',
    params,
  ],
  store: (params: FetchStoreParams) => ['store', params],
  stores: (params: FetchStoresParams) => ['stores', params],
  storesByLocation: (params: FetchStoresByLocationParams) => ['storesByLocation', params],
  storesByProductCode: (params: FetchStoresByProductCodeParams) => ['storesByProductCode', params],
};
