import CriteoLegalModal from 'components/Criteo/CriteoLegalModal/CriteoLegalModal';
import React, { forwardRef, useState } from 'react';
import { IconButton } from 'ui/Button/Button';
import { Signing } from 'ui/Signing/Signing';
import { cn } from 'utils/cn';
import { SigningType } from '../ProductCard/types';

const SponsoredProductSigning = forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & { className?: string; signing: SigningType }
>(({ className, signing }, ref) => {
  const { legalInfo } = signing;
  const hasLegalInfo = !!legalInfo?.behalf && !!legalInfo?.paid;

  const [showCriteoModal, setShowCriteoModal] = useState(false);

  return (
    <>
      <Signing
        ref={ref}
        className={cn('items-center', hasLegalInfo ? 'flex' : '', signing?.className, className)}
        style={{ backgroundColor: signing.hex }}
      >
        {signing.label}
        {hasLegalInfo && (
          <IconButton
            variant="ghost"
            className="h-fit w-fit border-none"
            onClick={() => setShowCriteoModal(true)}
            icon={{ name: 'info-circle', styling: 'far' }}
          />
        )}
      </Signing>

      {hasLegalInfo && (
        <CriteoLegalModal legalInfo={legalInfo} onClose={() => setShowCriteoModal(false)} open={showCriteoModal} />
      )}
    </>
  );
});

export default SponsoredProductSigning;
