import { ComponentProps } from 'react';
import { cn } from 'utils/cn';
import { Icon } from '../Icon/Icon';
import { Text, TextProps } from '../Text/Text';

interface Props extends TextProps {
  children?: number;
}

export const RatingMessage = ({ className, type = 'small', ...props }: ComponentProps<typeof Text>) => (
  <Text className={cn('text-accent-60', className)} type={type} {...props} />
);

const Rating = ({ children, className }: Props) => (
  <div className={cn('flex w-fit items-center gap-1 rounded-[40px] bg-accent-20 px-2 py-1', className)}>
    <Icon icon={{ name: 'star', styling: 'fas' }} className={`${children ? 'text-yellow-400' : 'text-accent-60'}`} />
    {children !== undefined && <Text className="font-body text-[0.875rem] leading-3">{children}</Text>}
  </div>
);

export { Rating };
