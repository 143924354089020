export const shuffleArray = <T>(array?: Array<T>) => {
  if (array?.length) {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }

    return newArray;
  }

  return [];
};

export const getLastItemInArray = <T>(array: T[]) => array?.length > 0 && array[array.length - 1];

export const getUniqueItems = (arr: string[]): string[] => {
  const uniqueSet = new Set(arr);
  return Array.from(uniqueSet);
};
