import usePrice from 'hooks/usePrice';
import { useIntl } from 'react-intl';
import { Price, PriceProps, StrikePrice } from 'ui/Price/Price';

// Wrapping component of the UI folder's Price component to enrich it with the intl messages
// TODO: Rename once the original Price component is removed
const PriceNew = ({ children, ...props }: PriceProps) => {
  const { formatMessage } = useIntl();
  const { formatPrice } = usePrice();
  const value = Number(children);

  return <Price {...props}>{value === 0 ? formatMessage({ id: 'cart_free_product_text' }) : formatPrice(value)}</Price>;
};

const StrikePriceNew = ({ children, ...props }: PriceProps) => {
  const { formatPrice } = usePrice();
  const value = Number(children);

  return <StrikePrice {...props}>{formatPrice(value)}</StrikePrice>;
};

export { PriceNew, StrikePriceNew };
