import { useIntl } from 'react-intl';
import { CriteoLegalInfo } from 'types/Criteo';

const useSigning = () => {
  const { formatMessage } = useIntl();

  const getSponsoredSigning = (legalInfo?: CriteoLegalInfo) => ({
    className: 'text-foreground',
    hex: '#F8F8F8',
    id: 'sponsored',
    label: formatMessage({ id: 'sponsored_product_signing' }),
    legalInfo: legalInfo,
  });

  return { getSponsoredSigning };
};

export default useSigning;
