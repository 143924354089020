import styled from 'styled-components';
import { variable } from 'theme';
import Button from '../Button/Button';

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: skewX(-11deg);
  border-radius: ${variable('border-radius-leaf')};

  > * {
    transform: skewX(11deg);
  }
`;
