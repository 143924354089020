import { ProductCarrier } from 'constants/product';
import { useIntl } from 'react-intl';
import { Product, RealTimeInfo } from 'types/Product';
import { getAtpMessage as _getAtpMessage, mapAtpMessage as _mapAtpMessage } from 'utils/atpUtil';
import { useFormatDate } from './useFormatDate';

export const useAtpMessage = () => {
  const { formatDate } = useFormatDate();
  const { formatMessage } = useIntl();

  const getAtpMessage = (
    stockMessage?: string,
    realtimeInfo?: RealTimeInfo,
    overruleStockMessage?: string,
    preOrderExpectedDeliveryDate?: string,
    isTradeplaceProduct?: boolean,
    firstAppearanceEndDate?: Date,
    shouldFormatStockMessage?: boolean,
    carrier?: ProductCarrier,
  ) =>
    _getAtpMessage(
      formatDate,
      formatMessage,
      stockMessage,
      realtimeInfo,
      overruleStockMessage,
      preOrderExpectedDeliveryDate,
      isTradeplaceProduct,
      firstAppearanceEndDate,
      shouldFormatStockMessage,
      carrier,
    );

  type MapAtpMessageParams = {
    product: Product;
    realtimeInfo?: RealTimeInfo;
  };

  const mapAtpMessage = ({ product, realtimeInfo }: MapAtpMessageParams) =>
    _mapAtpMessage({ formatDate, formatMessage, product, realtimeInfo });

  return { getAtpMessage, mapAtpMessage };
};
