import { useCookies } from 'react-cookie';
import { Dispatch } from 'redux';
import { CompareCookie, CompareGroup } from 'types/Compare';
import { Product } from 'types/Product';
import config from '../config';
import COMPARE from '../constants/compare';
import { updateProductCompare } from '../redux/modules/compare';

/**
 * Function which returns compare product object
 * @param {object} compareCookie - the currently selected products from cookies
 * @param {object} product - the product object
 * @param {object} category - the category of the selected product group
 * @param {object} extraProduct - the newly to add product to compare
 * @returns {object} the compare product object
 */
export const generateUpdatedCompareObject = (
  compareCookie: CompareCookie,
  product?: Product,
  compareGroup?: CompareGroup,
  extraProduct?: Product,
) => {
  const cookieComparedProductCodes = compareCookie?.comparedProductCodes || [];
  const cookieComparedCategoryCode = compareCookie?.comparedCategory?.code || null;
  const isNewCategory = compareGroup?.code !== cookieComparedCategoryCode;

  if (!product) {
    // Return pure cookie state if given product is null
    return {
      ...compareCookie,
      extraProduct: null,
      isOverLimit: false,
      showCompareMenu: true,
    };
  }

  // Create new array of exisitng cookie product codes
  // Or Clear current products if category has changed
  const newCompareProducts = isNewCategory ? [] : [...cookieComparedProductCodes];

  // Check if it's a new product or not
  const hasProductIndex = cookieComparedProductCodes?.indexOf(product.code);

  // Check to see if we can add a new product or not
  let isOverLimit = newCompareProducts.length === COMPARE.MAX_LENGTH && hasProductIndex === -1;

  if (hasProductIndex !== -1) {
    if (extraProduct && !isNewCategory) {
      // If the removed product needs to be replaced
      newCompareProducts[hasProductIndex] = extraProduct.code;
    } else {
      newCompareProducts.splice(hasProductIndex, 1);
    }
    // force to false, since isOverLimit === true, but we are removing or replacing one item.
    isOverLimit = false;
  } else if (!isOverLimit) {
    // push new product code
    newCompareProducts.push(product.code);
  }

  // if there are products show the menu otherwise hide it
  const hasProducts = !!newCompareProducts.length;

  return {
    comparedCategory: hasProducts ? compareGroup : null,
    comparedProductCodes: newCompareProducts,
    extraProduct: isOverLimit && !extraProduct ? product : null,
    isOverLimit,
    showCompareMenu: hasProducts,
  };
};

/**
 * Function which sets the new compare object
 * @param {object} product - the product object
 * @param {object} compareCookie - the currently selected products from cookies
 * @param {object} comparedCategory - the category of the selected product group
 * @param {object} extraProduct - the newly to add product to compare
 * @param {function} dispatch - the dispatch function
 * @param {function} setCookie - the setCookie hook from useCookies
 * @returns {object} the compare product object
 */
export const setNewCompareObject = (
  product: Product,
  compareCookie: CompareCookie,
  comparedGroup: CompareGroup,
  dispatch: Dispatch,
  setCookie: ReturnType<typeof useCookies>[1],
  extraProduct?: Product,
) => {
  if (product) {
    const compareObject = generateUpdatedCompareObject(compareCookie, product, comparedGroup, extraProduct);

    dispatch(
      // @ts-ignore - dispatch type error
      updateProductCompare(
        compareObject.comparedProductCodes,
        compareObject.comparedCategory,
        compareObject.showCompareMenu,
        compareObject.isOverLimit,
        compareObject.extraProduct,
      ),
    );

    const newCompareCookie = {
      comparedCategory: compareObject.comparedCategory,
      comparedProductCodes: compareObject.comparedProductCodes,
    };

    if (newCompareCookie) {
      setCookie(config.productCompareCookie.key, newCompareCookie, config.cookie);
    }
  }
};

/**
 * Function which returns the compareobject that has been updated with the cookie values
 * @param {object} compareCookie - the currently selected products from cookies
 * @returns {object} the compare product object
 */
export const updateStateToCookieValues = (compareCookie: CompareCookie) => ({
  comparedCategory: compareCookie.comparedCategory,
  comparedProductCodes: compareCookie.comparedProductCodes,
  extraProduct: null,
  isOverLimit: false,
  showCompareMenu: compareCookie.comparedProductCodes.length >= 1,
});

/**
 * Function which updates redux state to cookie values
 * @param {object} compareCookie - the currently selected products from cookies
 * @param {function} dispatch - the dispatch function
 * @returns {object} the compare product object
 */
export const updateStateToCookiesUtil = (compareCookie: CompareCookie, dispatch: Dispatch) => {
  const compareObject = updateStateToCookieValues(compareCookie);

  dispatch(
    // @ts-ignore - dispatch type error
    updateProductCompare(
      compareObject.comparedProductCodes,
      compareObject.comparedCategory,
      compareObject.showCompareMenu,
      compareObject.isOverLimit,
      compareObject.extraProduct,
    ),
  );
};

/**
 * Function which returns the product compare param
 * @param {Array} productCodes - the compared products array
 * @return {null|string} the product compare param
 */
export const getProductCompareParam = (productCodes: string[] = []) => productCodes.join(', ');
