import ProductPreview from 'components/ProductPreview/ProductPreview';
import PickupInStoreDetail from 'components/StoreFinder/PickupInStoreDetail/PickupInStoreDetail';
import Typography from 'components/Typography/Typography';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { Product } from 'types/Product';
import { Dialog, DialogContent, DialogHeader } from 'ui/Dialog/Dialog';

interface ProductStockModalProps {
  onAddToCart: () => void;
  product: Product;
  show?: boolean;
  toggleModal: () => void;
}
const ProductStockModal = ({ onAddToCart, product, show, toggleModal }: ProductStockModalProps) => {
  const { formatMessage } = useIntl();

  const triggerAddToCart = () => {
    onAddToCart();
    toggleModal();
  };

  return (
    <Dialog open={show} onOpenChange={toggleModal}>
      <DialogContent className="min-h-[90vh] gap-0 p-0 sm:min-h-[550px]">
        <DialogHeader className="sticky top-0 z-dialog bg-accent-20 p-6">
          <Typography marginBottom={150} textAlign="center" type="h3">
            {formatMessage({ id: 'store_nearby' })}
          </Typography>
          <ProductPreview product={product} />
        </DialogHeader>
        <PickupInStoreDetail
          className="p-6"
          onSelectStore={triggerAddToCart}
          productCode={product?.code}
          showOpeningHours={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(ProductStockModal, isEqual);
