import { buttonSizes } from 'components/Button/Button.styled';
import { ButtonSize, Spacer } from 'constants/styling';
import styled, { css } from 'styled-components';
import { color, spacer, variable } from 'theme';
import { Color } from 'types/Color';
import { isNumericValue } from 'utils/textUtil';
import ButtonLeaf, { ButtonLeafProps } from '../../ButtonLeaf/ButtonLeaf';

export interface StyledSelectableIconProps {
  active?: boolean;
  activeColor?: Color;
  backgroundColor?: Color;
  borderRadius?: Spacer | 'round';
  label?: string;
  showCircle?: boolean;
  size?: ButtonSize;
}

const activeIconWrapperStyling = css`
  background-color: ${color('secondary-300')};
  svg {
    color: ${color('white')};
    fill: ${color('white')};
  }
`;

export const StyledSelectableIcon = styled.div<StyledSelectableIconProps>`
  display: flex;
  gap: ${spacer(50)};
  align-items: center;
  justify-content: center;
  margin: 0px ${spacer(25)};

  &:hover {
    border-color: ${color('secondary-200')};
  }

  ${({ showCircle }) =>
    showCircle &&
    css`
      box-shadow: ${variable('box-shadow-soft')};
      border: ${variable('border-transparent')};
      border-radius: 50%;
      min-width: 44px;
      max-width: 44px;
      height: 44px;
      background-color: ${color('white')};
    `}

  ${({ active, showCircle }) => active && showCircle && activeIconWrapperStyling}

   ${({ active, activeColor, label }) =>
    active &&
    activeColor &&
    !!label &&
    css`
      color: ${color(activeColor)};
      svg {
        color: ${color(activeColor)};
        fill: ${color(activeColor)};
      }
    `}
    ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${color(backgroundColor)};
    `}
    ${({ borderRadius }) =>
    borderRadius === 'round'
      ? css`
          border-radius: 50%;
        `
      : borderRadius &&
        css`
          border-radius: ${spacer(borderRadius)};
        `}
  ${({ size }) => {
    if (!size) return null;
    const stringSize = buttonSizes[size];

    if (stringSize) return stringSize;

    if (isNumericValue(size)) {
      return css`
        padding: ${spacer(size)} ${spacer(size)};
      `;
    }

    return null;
  }}
`;

interface StyledLeafProps extends ButtonLeafProps {
  active?: boolean;
}

export const StyledLeaf = styled(ButtonLeaf)<StyledLeafProps>`
  border: ${variable('border-transparent')};
  box-shadow: ${variable('box-shadow-soft')};
  background-color: ${color('white')};

  svg {
    color: ${color('black')};
    fill: ${color('black')};
  }

  ${({ active }) => active && activeIconWrapperStyling}

  &:hover, &:focus, &:active {
    border-color: ${color('secondary-300')};
    background-color: ${color('white')};

    svg {
      color: ${color('secondary-300')};
      fill: ${color('secondary-300')};
    }
  }
`;
