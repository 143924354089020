import useAuthResolver from 'hooks/useAuthResolver';
import { useFormatDate } from 'hooks/useFormatDate';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Product } from 'types/Product';
import { Store } from 'types/Store';
import { Icon } from 'ui/Icon/Icon';
import { TextLink } from 'ui/Link/Link';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';
import { PRODUCT_STOCK_MESSAGE } from '../../../constants/product';
import useUpdateCart from '../../../hooks/useUpdateCart';
import { checkProductInStockByStockmessage } from '../../../utils/productUtil';
import ProductStockModal from '../ProductStockDetails/ProductStockModal/ProductStockModal';

interface ProductDeliveryInfo {
  atpDescription?: string;
  availableInStores?: Store[];
  className?: string;
  deliveryPromise?: string;
  product: Product;
  stockMessage?: string;
}

const Wrapper = ({ children }: { children?: ReactNode }) => (
  <div className="flex flex-row gap-3">
    <Icon icon={{ name: 'check', styling: 'fas' }} className="size-6" /> {children}
  </div>
);

const ProductDeliveryInfo = ({
  atpDescription,
  availableInStores = [],
  className,
  deliveryPromise,
  product,
  stockMessage,
}: ProductDeliveryInfo) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatDate();

  const { user } = useAuthResolver();

  const { addToCart } = useUpdateCart();

  const [showStockModal, setShowStockModal] = useState(false);

  const deliveryDate =
    deliveryPromise &&
    formatDate(deliveryPromise, {
      day: '2-digit',
      month: 'long',
      weekday: 'long',
    });
  const matchingFavouriteStores =
    user?.favouriteStores?.filter((favStore) =>
      availableInStores.some((store) => store?.displayName === favStore?.displayName),
    ) ?? [];

  const productInStock = checkProductInStockByStockmessage(stockMessage);
  const productPickup = stockMessage === PRODUCT_STOCK_MESSAGE.EXPOMODEL_ORDER;

  const storeNames =
    (matchingFavouriteStores?.length && matchingFavouriteStores) ||
    (user?.nearbyStores?.length && user?.nearbyStores) ||
    [];

  return (
    <div className={cn('flex flex-col gap-4', className)}>
      {/* FIX ME Add functiality so that selected store postalcode and town are prefilled in the DeliveryPostalCodeSelect input field */}
      {product && (
        <ProductStockModal
          onAddToCart={() => addToCart({ product })}
          product={product}
          show={showStockModal}
          toggleModal={() => {
            setShowStockModal(false);
          }}
        />
      )}

      {!!storeNames?.length && (
        <Wrapper>
          <Text className="text-sm">
            {formatMessage({
              id: 'available_in_favourite_shops',
            })}
            <div>
              {storeNames.map((store, index) => (
                <TextLink
                  key={store.displayName}
                  onClick={() => {
                    setShowStockModal(true);
                  }}
                  className="inline text-sm text-primary-40"
                >
                  {store?.displayName}
                  {index !== storeNames.length - 1 ? ', ' : ''}
                </TextLink>
              ))}
            </div>
          </Text>
        </Wrapper>
      )}

      {!!availableInStores?.length && (
        <Wrapper>
          <Text className="text-sm">
            {formatMessage(
              {
                id: availableInStores?.length > 1 ? 'available_in_shops' : 'available_in_shop',
              },
              { amount: availableInStores?.length },
            )}

            <TextLink onClick={() => setShowStockModal(true)} className="text-sm text-primary-40">
              {formatMessage({ id: 'look_at_shops' })}
            </TextLink>
          </Text>
        </Wrapper>
      )}

      {!!productInStock && !!deliveryDate && !productPickup && (
        <Wrapper>
          <Text className="text-sm">{formatMessage({ id: 'deliveries_at_home' }, { date: deliveryDate })}</Text>
        </Wrapper>
      )}

      {!!atpDescription && (
        <Wrapper>
          <Text className="text-sm">{atpDescription}</Text>
        </Wrapper>
      )}
    </div>
  );
};

export default ProductDeliveryInfo;
