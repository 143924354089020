import { useIntl } from 'react-intl';
import { OPENING_TEXT_SCHEDULE_TYPES, OpeningTextScheduleType, WEEK_DAYS } from '../../../../constants/store';
import Typography from '../../../Typography/Typography';
import DayTextSchedule from './DayTextSchedule/DayTextSchedule';
import { StoreOpeningDay } from 'types/Store';
import Flex from 'components/Flex/Flex';

interface OpeningTextScheduleProps {
  title?: string;
  type?: OpeningTextScheduleType;
  weekDayOpeningList?: StoreOpeningDay[];
}

const OpeningTextSchedule = ({
  title,
  type = OPENING_TEXT_SCHEDULE_TYPES.DEFAULT,
  weekDayOpeningList,
}: OpeningTextScheduleProps) => {
  const { formatMessage } = useIntl();

  if (!weekDayOpeningList?.length) return null;

  return (
    <Flex flexDirection="column">
      {title && (
        <Typography fontWeight="bold" type="p" marginBottom={0}>
          {title}
        </Typography>
      )}

      <table>
        <tbody>
          {weekDayOpeningList.map((daySchedule, index) => {
            if (type === OPENING_TEXT_SCHEDULE_TYPES.SPECIAL_OPENING) {
              return (
                <DayTextSchedule
                  key={daySchedule?.comment}
                  closingDate={daySchedule?.closingTime}
                  openingDate={daySchedule?.openingTime}
                  closed={daySchedule?.closed}
                  label={daySchedule?.comment}
                />
              );
            }

            if (daySchedule.weekDay && WEEK_DAYS[daySchedule.weekDay]) {
              const weekDay = WEEK_DAYS[daySchedule.weekDay];

              return (
                <DayTextSchedule
                  key={formatMessage({ id: weekDay })}
                  closingDate={daySchedule?.closingTime}
                  isActive={index === 0}
                  label={formatMessage({ id: weekDay })}
                  openingDate={daySchedule?.openingTime}
                  closed={daySchedule?.closed}
                />
              );
            }

            return null;
          })}
        </tbody>
      </table>
    </Flex>
  );
};

export default OpeningTextSchedule;
