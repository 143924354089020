import ProductDeliveryInfo from 'components/Product/ProductDeliveryInfo/ProductDeliveryInfo';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';
import { Product } from 'types/Product';
import { Store } from 'types/Store';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../Dialog/Dialog';
import { Icon, IconProps } from '../Icon/Icon';
import { Text, TextProps } from '../Text/Text';
type Variant = 'success' | 'error';

type Props = TextProps & {
  atpDescription?: string;
  availableInStores?: Store[];
  canBeSold?: boolean;
  children: string;
  deliveryPromise?: string;
  preOrderLabel?: string;
  product: Product;
  stockMessage?: string;
  variant?: Variant;
  withIcon?: boolean;
};

const variants = tv({
  base: 'w-fit flex items-center gap-1 text-left',
  variants: {
    variant: {
      error: 'text-accent-100 [&>svg]:text-accent-60',
      success: 'text-success',
    },
  },
});

const iconMap: Record<Variant, IconProps['icon']> = {
  error: {
    name: 'circle-xmark',
    styling: 'fas',
  },
  success: {
    name: 'check-circle',
    styling: 'fas',
  },
};

// TODO: Refactor this back to a proper ui component, it's now poluted with logic
const ATPMessage = ({
  atpDescription,
  availableInStores,
  canBeSold,
  children,
  className,
  deliveryPromise,
  preOrderLabel,
  product,
  stockMessage,
  tag = 'span',
  type = 'small',
  variant = 'success',
  withIcon = true,
}: Props) => {
  const { formatMessage } = useIntl();

  const defaultAtp = (
    <Text type={type} tag={tag} className={variants({ className, variant })}>
      {withIcon && (
        <div>
          <Icon icon={iconMap[variant]} />
        </div>
      )}
      {children}
    </Text>
  );

  const atpMessageTrigger = preOrderLabel ? (
    <div className="flex flex-col gap-1">
      {!!preOrderLabel && (
        <Text type={type} tag={tag} className={variants({ className, variant })}>
          {preOrderLabel}
        </Text>
      )}
      {defaultAtp}
    </div>
  ) : (
    defaultAtp
  );

  const showModal = !!canBeSold && (availableInStores?.length || deliveryPromise || stockMessage || atpDescription);

  return showModal ? (
    <Dialog>
      <DialogTrigger>{atpMessageTrigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {formatMessage({
              id: 'product_atp_delivery_modes_overlay_title',
            })}
          </DialogTitle>
        </DialogHeader>

        <ProductDeliveryInfo
          product={product}
          availableInStores={availableInStores}
          deliveryPromise={deliveryPromise}
          stockMessage={stockMessage}
          atpDescription={atpDescription}
        />
      </DialogContent>
    </Dialog>
  ) : (
    atpMessageTrigger
  );
};

export { ATPMessage };
