import styled, { css } from 'styled-components';
import { breakpointOnly, breakpointUp, color, spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import Typography from '../../../Typography/Typography';

export const StyledPickupInStoreItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${spacer(75)};
  margin-top: ${spacer(75)};
  padding: ${spacer(150)};
  border-radius: ${variable('border-radius')};
  ${variable('border')}

  &:empty {
    height: 180px;
    background-color: ${color('whisper-100')};
  }
`;

export const StyledPickupInStoreItemInfoAtc = styled.div`
  margin-top: ${spacer(100)};

  ${breakpointUp(
    BREAKPOINTS.SM,
    css`
      margin-left: auto;
    `,
  )}
`;

export const StyledPickupInStoreItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  ${breakpointUp(
    BREAKPOINTS.SM,
    css`
      width: auto;
    `,
  )};
`;

export const StyledPickupInStoreItemStockItemMessage = styled(Typography)`
  ${breakpointOnly(
    BREAKPOINTS.XS,
    BREAKPOINTS.SM,
    css`
      text-align: left !important;
    `,
  )}

  ${breakpointOnly(
    BREAKPOINTS.MD,
    BREAKPOINTS.LG,
    css`
      text-align: left !important;
    `,
  )}

  /* iPad Mini horizontal view */
  @media only screen and (min-width: 993px) and (max-width: 1072px) {
    text-align: left !important;
  }
`;

export const StyledPickupInStoreItemHourMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacer(25)};

  ${breakpointOnly(
    BREAKPOINTS.XS,
    BREAKPOINTS.SM,
    css`
      justify-content: left;
    `,
  )}

  ${breakpointOnly(
    BREAKPOINTS.MD,
    BREAKPOINTS.LG,
    css`
      justify-content: left;
    `,
  )}

  /* iPad Mini horizontal view */
  @media only screen and (min-width: 993px) and (max-width: 1072px) {
    justify-content: left;
  }
`;
