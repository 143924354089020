import { CustomerServiceDetails, Store, StoresResult } from 'types/Store';
import { LAYOUT_STATES } from '../../constants/store';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

export type FetchStoresParams = {
  latitude?: number;
  locale: string;
  longitude?: number;
  open?: boolean;
  openSunday?: boolean;
  signings?: string;
};

const fetchStores = async ({ latitude, locale, longitude, open, openSunday, signings }: FetchStoresParams) => {
  try {
    const { data } = await apiClient<StoresResult>({
      params: {
        fields: 'STORE_FINDER',
        pageSize: 100,
        ...(latitude && { latitude }),
        ...(longitude && { longitude }),
        ...(open && { open }),
        ...(openSunday && { openSunday }),
        ...(signings && { signings }),
        lang: locale,
      },
      url: '/stores',
    });
    return data;
  } catch (error) {
    logErrorResponse('Stores Connector', error, 'Error fetching stores');
    throw error;
  }
};

export type FetchStoreParams = {
  locale: string;
  storeId?: string;
};

const fetchStore = async ({ locale, storeId }: FetchStoreParams) => {
  try {
    if (!storeId) throw new Error('No store id provided');
    const { data } = await apiClient<Store>({
      params: {
        fields: 'STORE_FINDER_DETAIL',
        lang: locale,
      },
      url: `/stores/${storeId}`,
    });

    return { ...data, layoutState: LAYOUT_STATES.DETAIL, zoom: 15 };
  } catch (error) {
    logErrorResponse('Stores Connector', error, `Error fetching store with id ${storeId}`);
    throw error;
  }
};

export type FetchCustomerServiceDetailsParams = {
  locale: string;
};

const fetchCustomerServiceDetails = async ({ locale }: FetchCustomerServiceDetailsParams) => {
  try {
    const { data } = await apiClient<CustomerServiceDetails>({
      params: {
        fields: 'FULL',
        lang: locale,
      },
      url: '/customerservice/details',
    });
    return data;
  } catch (error) {
    logErrorResponse('Stores Connector', error, `Error fetching customer service details`);
    throw error;
  }
};

export type FetchStoresByLocationParams = {
  countryCode: string;
  fields?: string;
  locale: string;
  location: string;
  pageSize?: number;
  postalCode: string;
};

const fetchStoresByLocation = async ({
  countryCode,
  fields,
  locale,
  location,
  pageSize,
  postalCode,
}: FetchStoresByLocationParams) => {
  try {
    const { data } = await apiClient<{ stores: Store[] }>({
      params: {
        country: countryCode,
        fields,
        lang: locale,
        location,
        pageSize,
        postalCode,
      },
      url: '/stores/location',
    });
    return data;
  } catch (error) {
    logErrorResponse('Stores Connector', error, `Error fetching  stores by location`);
    throw error;
  }
};

export type FetchStoresByProductCodeParams = {
  countryCode: string;
  locale: string;
  location: string;
  postalCode: string;
  productCode: string;
};

const fetchStoresByProductCode = async ({
  countryCode,
  locale,
  location,
  postalCode,
  productCode,
}: FetchStoresByProductCodeParams) => {
  try {
    const { data } = await apiClient<{ stores: Store[] }>({
      params: {
        country: countryCode,
        fields: 'FULL',
        lang: locale,
        location,
        postalCode,
        productCode,
      },
      url: '/stores/location',
    });
    return data;
  } catch (error) {
    logErrorResponse('Stores Connector', error, `Error fetching stores by product code`);
    throw error;
  }
};

export type FetchPickupLocationsByProductCodeParams = {
  countryCode?: string;
  locale: string;
  location: string;
  postalCode: string;
  productCode: string;
};

export const fetchPickupLocationsByProductCode = async ({
  countryCode,
  locale,
  location,
  postalCode,
  productCode,
}: FetchPickupLocationsByProductCodeParams) => {
  try {
    const { data } = await apiClient<{ pointOfServices: Store[] }>({
      params: {
        country: countryCode,
        fields: 'PICKUP',
        lang: locale,
        location,
        postalCode,
      },
      url: `/products/${productCode}/pickuplocations`,
    });
    return data;
  } catch (error) {
    logErrorResponse('Stores Connector', error, `Error fetching stock locations by product code`);
    throw error;
  }
};

export { fetchCustomerServiceDetails, fetchStore, fetchStores, fetchStoresByLocation, fetchStoresByProductCode };
