import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CartEntry } from 'types/Cart';
import { Product } from 'types/Product';
import { ANALYTIC_EVENTS, AnalyticEvent } from '../constants/analytics';
import { getLastItemInArray } from '../utils/arrayUtil';
import useAppEvents from './useAppEvents';
import useAuthResolver from './useAuthResolver';
import useCart from './useCart';

interface AddToCartProps {
  analyticsList?: string;
  bundleCode?: string;
  callback?: () => void;
  event?: AnalyticEvent;
  hasSelectedExtraWarranty?: boolean;
  position?: number;
  product?: Product;
  productCodes?: string[];
}

interface RemoveProductProps {
  event?: AnalyticEvent;
  product: Product;
}

interface RemoveEntryProps {
  entry: CartEntry;
  event?: AnalyticEvent;
}

const useUpdateCart = () => {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { anonymous } = useAuthResolver();
  const { cart, onAddConfigurationToCart, onAddToCart, onRemoveEntry, onRemoveProduct } = useCart();

  const { sendAppEvent } = useAppEvents();

  const addToCart = async ({
    analyticsList,
    bundleCode,
    callback,
    event = ANALYTIC_EVENTS.ADD_TO_CART,
    hasSelectedExtraWarranty = false,
    position,
    product,
    productCodes,
  }: AddToCartProps) => {
    setLoading(true);
    await onAddToCart({
      analyticsList,
      bundleCode,
      callback,
      event,
      formatMessage,
      hasSelectedExtraWarranty,
      position,
      product,
      productCodes,
      sendAppEvent,
    });
    setLoading(false);
  };

  const removeProduct = async ({ event = ANALYTIC_EVENTS.REMOVE_FROM_CART, product }: RemoveProductProps) => {
    if (!cart) return;
    setLoading(true);
    await onRemoveProduct(dispatch, cart, anonymous, product, event, sendAppEvent);
    setLoading(false);
  };

  const removeEntry = async ({ entry, event = ANALYTIC_EVENTS.REMOVE_FROM_CART }: RemoveEntryProps) => {
    if (!cart) return;
    setLoading(true);
    await onRemoveEntry(dispatch, cart, anonymous, entry, event, sendAppEvent);
    setLoading(false);
  };

  const getLastCartEntry = (array: CartEntry[]) => getLastItemInArray(array);

  const addConfigurationToCart = async (configurationUid: string) => {
    setLoading(true);
    await onAddConfigurationToCart({ anonymous, cart, configurationUid, dispatch, sendAppEvent });
    setLoading(false);
  };

  const addCustomBundleToCart = async ({
    baseProduct,
    bundleCode,
    selectedAccessoriesProductCodes,
  }: {
    baseProduct: Product;
    bundleCode: string;
    selectedAccessoriesProductCodes: string[];
  }) => {
    await addToCart({ bundleCode, product: baseProduct, productCodes: selectedAccessoriesProductCodes });
  };

  return {
    addConfigurationToCart,
    addCustomBundleToCart,
    addToCart,
    getLastCartEntry,
    loading,
    removeEntry,
    removeProduct,
  };
};

export default useUpdateCart;
