import { useWebConfig } from 'features/configuration/queries';
import { PostalCode } from 'types/PostalCode';
import useAuthResolver from './useAuthResolver';

const useSuggestedPickupLocation = (): PostalCode | undefined => {
  const { user } = useAuthResolver();
  const defaultAddress = user?.defaultAddress;

  const { data: webConfig } = useWebConfig();

  if (defaultAddress) {
    const { country, postalCode, town } = defaultAddress;
    if (postalCode && town && country) {
      return {
        country: country.isocode,
        name: town,
        postalCode,
      };
    }
  }

  return webConfig?.defaultPickupLocation;
};

export default useSuggestedPickupLocation;
