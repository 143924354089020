import config from 'config';
import { ANALYTIC_EVENTS } from 'constants/analytics';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import { useCookies } from 'react-cookie';
import { useNotificationActions } from 'store/notification';
import { useWishlistActions, useWishlistRealTimeInfo } from 'store/wishlist';
import { Product } from 'types/Product';
import { pushAddToWishlistEvent } from 'utils/analyticsUtil';
import { getUniqueItems } from 'utils/arrayUtil';
import { createNotification } from 'utils/notifications';
import useAuthResolver from './useAuthResolver';
import { useCriteoTracking } from './useCriteoTracking';

const useWishlist = () => {
  const { anonymous } = useAuthResolver();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { queueNotification } = useNotificationActions();
  const { toggleWishListOverlay } = useWishlistActions();
  const { pushCriteoBeacon } = useCriteoTracking();
  const apiProducts = useWishlistRealTimeInfo();
  const apiProductCodes = apiProducts.reduce((prev: string[], curr) => {
    if (curr.isInWishlist) {
      return [...prev, curr.code];
    }

    return prev;
  }, []);
  const cookieProductCodes: string[] = cookies[config.wishListCookie.key] ?? [];
  const productCodes = anonymous ? cookieProductCodes : apiProductCodes;

  const addProduct = (product: Product) => {
    if (anonymous) {
      setCookie(config.wishListCookie.key, getUniqueItems([product.code, ...productCodes]), config.cookie);
      queueNotification(createNotification({ type: NOTIFICATION_TYPES.ADDED_TO_WISHLIST }));
    } else {
      toggleWishListOverlay(true, product);
    }

    pushAddToWishlistEvent(ANALYTIC_EVENTS.ADD_TO_WISHLIST, product);
    if (product?.tracking?.OnWishlistBeacon) {
      pushCriteoBeacon?.(product.tracking.OnWishlistBeacon);
    }
  };

  const removeProduct = (product: Product) => {
    if (anonymous) {
      const products = cookieProductCodes?.filter((code) => code !== product.code);
      if (products?.length > 0) {
        setCookie(config.wishListCookie.key, products, config.cookie);
      } else {
        removeCookie(config.wishListCookie.key, config.cookie);
      }
    } else {
      toggleWishListOverlay(true, product);
    }
  };

  const toggleProduct = (product: Product) => {
    if (exists(product.code)) {
      removeProduct(product);
    } else {
      addProduct(product);
    }
  };

  const exists = (code: string) => productCodes.includes(code);

  return {
    addProduct,
    exists,
    productCodes,
    removeProduct,
    toggleProduct,
  };
};

export default useWishlist;
