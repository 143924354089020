import Box from 'components/Box/Box';
import Typography from 'components/Typography/Typography';
import { useIntl } from 'react-intl';
import { StoreOpeningDayTime } from 'types/Store';

interface DayTextScheduleProps {
  closed?: boolean;
  closingDate?: StoreOpeningDayTime;
  isActive?: boolean;
  label?: string;
  openingDate?: StoreOpeningDayTime;
}

const DayTextSchedule = ({ closed, closingDate, isActive, label, openingDate }: DayTextScheduleProps) => {
  const { formatMessage } = useIntl();

  const openingHour = openingDate?.formattedHour;
  const closingHour = closingDate?.formattedHour;

  const hours = closed ? formatMessage({ id: 'general_store_closed' }) : `${openingHour} - ${closingHour}`;

  const currentLabel = isActive ? formatMessage({ id: 'general_store_today_label' }) : label;

  return (
    <tr>
      <td>
        <Typography color={isActive ? 'black' : 'primary-400'} fontWeight={isActive ? 'bold' : 'normal'}>
          {currentLabel}
        </Typography>
      </td>

      <td>
        <Box paddingLeft={400}>
          <Typography fontWeight={isActive ? 'bold' : 'normal'}>{hours}</Typography>
        </Box>
      </td>
    </tr>
  );
};

export default DayTextSchedule;
